// @flow

export const profile = {
  space_s: "1rem",
  space_m: "1.4375rem"
};

export const iconSize = {
  space_s: "1em",
  space_m: "1.3em",
  space_l: "2em",
  space_xl: "2.75em",
  sm: "16px",
  base: "24px"
};

export const minChatWidth = "200px";
export const maxChatWidth = "580px";

export const menuWidth = "250px";

// @flow

import {
  importProcessesFromJSON,
  importJSONCollisions
} from "src/api/workflow";
import type {
  ImportedProcess,
  ImportedProcessStatus,
  PrincipalChecklist,
  SectionMappedChecklistFields,
  UnifizeDropdownElement
} from "src/types";

/**
 * Imports the given list of JSON imported processes using the import
 * API
 * @param {string} filename - The UUID of the uploaded JSON returned by
 * the storage
 * @param {Array<ImportedProcess>} importProcesses - A list of processes
 * parsed out from the JSON file
 */
const handleJSONImport = async (
  filename: string,
  importProcesses: Array<ImportedProcess>
) => {
  let copies = [];
  let excludes = [];
  importProcesses.forEach(p => {
    if (p.copy) {
      copies.push(p.id);
    } else if (p.exclude) {
      excludes.push(p.id);
    }
  });
  const res = await importProcessesFromJSON(
    filename,
    excludes || null,
    copies || null
  );
  const result: Array<
    ImportedProcess & {
      status: ImportedProcessStatus
    }
  > = await res.json();
  return result;
};

/**
 * Fetches the list of processes colliding for the given JSON-import
 * file and returns it
 * @param {string} filename - The UUID of the uploaded JSON returned by
 * the storage
 * @return A list of the collision processes or null
 */
const fetchJSONImportCollisions = async (
  fileName: string
): Promise<?{ [string]: Array<ImportedProcess> }> => {
  try {
    const res = await importJSONCollisions(fileName);
    const data: { collisions: Array<ImportedProcess> } = await res.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Appends a '(1)' and a zero-width space Unicode character to the given
 * JSON import process's unmodified title as a modification flag.
 * Removes the character if it's already present.
 * @param {ImportedProcess} process - The imported process to be used
 * for the title
 * @return {string} The modified title
 */
const appendImportTitleString = (process: ImportedProcess) => {
  if (process.title.endsWith("\u200B")) {
    return process.title.slice(0, -5);
  } else {
    return process.title.concat(" (1)\u200B");
  }
};

/**
 * Maps checklist fields per their sections
 *
 * @return {[PrincipalChecklistFieldType, ?PrincipalChecklistFieldType ]}
 * A tuple with the first element being the field followed by its
 * section string or null
 */
const mapFieldsBySection = (
  fields: $PropertyType<PrincipalChecklist, "fields">
): SectionMappedChecklistFields => {
  let mappedFields = [];
  let currentSection = null,
    currentSubsection;
  for (const field of fields) {
    if (field.type === "section") {
      currentSection = field;
    } else if (field.type === "subSection") {
      currentSubsection = field;
    } else {
      mappedFields.push([field, currentSection, currentSubsection]);
    }
  }
  return mappedFields;
};

/**
 * Maps a single SectionMappedChecklistField into a unifize dropdown
 * element object with the relevant placement for sections, subsections
 * and their icons
 * @param {[PrincipalChecklistFieldType,?PrincipalChecklistFieldType ?PrincipalChecklistFieldType]} sectionMappedField -
 * A tupe containing the checklist field, its section and subsection
 * @return {UnifizeDropdownElement} - A dropdown element object with the
 * cells corresponding to the given section-mapped checklist field
 */
const mapFieldIntoRow = (
  sectionMappedField: $ElementType<SectionMappedChecklistFields, 0>
): UnifizeDropdownElement =>
  sectionMappedField[1]
    ? {
        cells: [
          [sectionMappedField[0].label, ""],
          [
            sectionMappedField[1] ? sectionMappedField[1]?.label : null,
            sectionMappedField[1] ? "fadedSection" : ""
          ],
          [
            sectionMappedField[2] ? sectionMappedField[2]?.label : null,
            sectionMappedField[2] ? "fadedSubSection" : ""
          ]
        ],
        id: String(sectionMappedField[0].id)
      }
    : {
        cells: [[sectionMappedField[0].label, ""]],
        id: String(sectionMappedField[0].id)
      };
export {
  handleJSONImport,
  fetchJSONImportCollisions,
  appendImportTitleString,
  mapFieldsBySection,
  mapFieldIntoRow
};

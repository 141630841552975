// @flow
import React from "react";

const FadedSection = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="divider 3" clipPath="url(#clip0_1848_1531)">
      <g id="Group">
        <path
          id="Vector"
          d="M13.1559 0.96875H2.84338V5.65625H13.1559V0.96875Z"
          stroke="#FCCEEE"
          strokeWidth="1.42205"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M13.1559 10.3438H2.84338V15.0312H13.1559V10.3438Z"
          stroke="#FCCEEE"
          strokeWidth="1.42205"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M0.968384 8H15.0309"
          stroke="#FCCEEE"
          strokeWidth="1.42205"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1848_1531">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FadedSection;

// @flow

import React from "react";
import { useSelector } from "react-redux";
import { SortableContainer } from "react-sortable-hoc";

import FieldSelect from "./FieldSelect";
import { Label, FormGroupSmall, ErrorMessage } from "../styles";
import { AddField } from "./styles";
import Icon from "src/icons";
import SelectProcess from "src/containers/workflow/select";
import { VStack } from "@chakra-ui/react";
import { getIsTemplateDeleted } from "src/reducers";

const FieldList = SortableContainer(({ children }) => {
  return <VStack alignItems="start">{children}</VStack>;
});

type Props = {
  workflow: ?number,
  fields: Array<number>,
  handleTemplate: Function,
  addField: Function,
  handleField: Function,
  removeField: Function,
  onSortFields: Function
};

const Workflow = ({
  workflow,
  fields,
  handleTemplate,
  addField,
  handleField,
  removeField,
  onSortFields
}: Props) => {
  const isTemplateDeleted = useSelector(({ app }) => getIsTemplateDeleted(app));

  return (
    <>
      <FormGroupSmall>
        <Label>Process</Label>
        <SelectProcess workflowId={workflow} handleChange={handleTemplate} />
        {isTemplateDeleted && <ErrorMessage>Process was deleted</ErrorMessage>}
      </FormGroupSmall>

      <div style={{ width: "100%" }}>
        <Label>EMBEDDED FIELDS</Label>
        <FieldList useDragHandle onSortEnd={onSortFields}>
          {!isTemplateDeleted &&
            fields.map((field: ?number, index) => (
              <FieldSelect
                index={index}
                itemIndex={index}
                key={`item-${field ?? -1}-${index}`}
                handleField={handleField}
                removeField={removeField}
                addField={addField}
                fields={fields}
                value={field}
              />
            ))}
        </FieldList>
      </div>

      <AddField
        data-cy="addEmbeddedFieldButton"
        type="button"
        disabled={
          !workflow ||
          isTemplateDeleted ||
          fields[fields.length - 1] === null ||
          fields.indexOf(null) !== -1
        }
        onClick={addField}
      >
        <Icon type="hollowPlus" />
        <span>Add Embeded field</span>
      </AddField>
    </>
  );
};

export default Workflow;

// @flow

import styled from "styled-components";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";

const Dropdown = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 15px;
  width: 90%;
`;

const DropdownMenu = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  visibility: ${props => (props.hidden ? "hidden" : "visible")};
`;

const DropdownContent = styled(Dropdown)`
  padding: ${props => (props.hidden ? "0" : "0.5rem")};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 19rem;
  overflow: auto;
  z-index: 1;
`;

const DropdownButton = styled.button`
  display: flex;
  background-color: white;
  padding: 10px 8px;
  border: 1px solid ${colors.grey40};
  border-radius: ${radius.small};
`;

// TODO Replace colors with constants
const DropdownElement = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: ${props => (props.static ? "inherit" : "14px")};
  color: ${colors.grey42};
  padding: ${props => (props.static ? "0" : "8px 16px")};
  background-color: ${props => (props.highlighted ? "#f3f8ff" : "#fff")};
`;

const DropdownCell = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
  min-width: 100px;
  flex: 2;
  &:not(:only-child):nth-child(n + 2) {
    flex: 1;
  }
  svg {
    flex-shrink: 0;
  }
  &:nth-of-type(1) {
    color: ${colors.primary};
  }
`;
const DropdownCellContent = styled.span`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SearchInput = styled.input`
  display: ${props => (props.hidden ? "none" : "inline")};
  height: 2rem;
  border: none;
  border-radius: ${radius.medium};
  padding: 6px;
  &:focus {
    border: 1px solid ${colors.lightBlue600};
  }
`;

export {
  Dropdown,
  DropdownMenu,
  DropdownContent,
  DropdownButton,
  DropdownElement,
  DropdownCell,
  DropdownCellContent,
  SearchInput
};

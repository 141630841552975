// @flow
import React from "react";

const FadedSubSection = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 2679">
      <g id="Group">
        <path
          id="Vector"
          d="M15 3.75H5V8.29545H15V3.75Z"
          stroke="#D6BBFB"
          strokeWidth="0.919305"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15 12.8409H5V17.3863H15V12.8409Z"
          stroke="#D6BBFB"
          strokeWidth="0.919305"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.18195 10.5681L12.2729 10.5681"
          stroke="#D6BBFB"
          strokeWidth="0.919305"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default FadedSubSection;
